<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <form class="needs-validation" @submit.prevent="Form">
            <div class="card-body">
              <h4 class="card-title"></h4>

              <div class="row mt-2">
                <div class="row">
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="subjectCode">เลขที่หัวข้อการตรวจ:</label>
                      <input
                        v-model="form.subjectCode"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="nameTh">หัวข้อ(ไทย):</label>
                      <input
                        v-model="form.nameTh"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.nameTh.required">{{ error }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltiplame">หัวข้อ(อังกฤษ):</label>
                      <input
                        v-model="form.nameEn"
                        type="text"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="type">ประเภท:</label>
                      <multiselect
                        id="type"
                        v-model="form.type"
                        :options="typeOption"
                        :show-labels="false"
                        :class="{
                          'is-invalid': submitform && $v.form.type.$error,
                        }"
                        label="nameTh"
                        track-by="nameTh"
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span></multiselect
                      >

                      <div
                        v-if="submitform && $v.form.type.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.type.required">{{ error }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6">
                    <div class="mb-3 position-relative">
                      <label for="">&nbsp;</label><br />
                      <b-form-checkbox
                        v-model="form.manageBranch"
                        value="1"
                        unchecked-value="0"
                        class="mb-3"
                        checked
                        plain
                        >ส่งข้อมูลไปทุกสาขา</b-form-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-end">
                <button type="submit" class="btn btn-success">บันทึก</button>
                &nbsp;
              </div>
            </div>
          </form>

          <hr />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
// import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    // DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.auditSubject,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "เพิ่มหัวข้อการตรวจมาตรฐาน",
      items: [
        {
          text: "ตรวจมาตรฐาน",
          active: true,
        },
        {
          text: appConfig.auditSubject,
          href: "/dealer-audit-subject",
        },
        {
          text: "เพิ่มหัวข้อการตรวจมาตรฐาน",
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],

      startIndex: "",
      endIndex: "",
      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "buildingNumber",
          sortable: true,
          label: "บ้านเลขที่",
        },
        {
          key: "districtNameTh",
          sortable: true,
          label: "ตำบล",
        },
        {
          key: "amphurNameTh",
          sortable: true,
          label: "อำเภอ",
        },
        {
          key: "provinceNameTh",
          sortable: true,
          label: "จังหวัด",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      readinessOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "ยังไม่พร้อม", value: "0" },
      ],
      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],

      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      typeOption: [{ nameTh: "หลังการขาย", id: "SALE" }],
      form: {
        manageBranch: "",
        subjectCode: "",
        type: "",
        nameTh: "",
        nameEn: "",
      },
      provinceId: "",
      amphurId: "",
      districtId: "",
      addressform: {
        buildingNumber: "", //บ้านเลขที่
        townshipNumber: "", //หมู่
        townshipName: "", //ชื่อหมู่บ้าน
        // subLane: "", //ตรอก
        lane: "", //ซอย
        streetName: "", //ชื่อถนน
        districtId: "", //ตำบล
        amphureId: "", //อำเภอ
        provinceId: "", //จังหวัด
        postCode: "", //รหัสไปรษณีย์
        roomnumber: "", //เลขห้อง
        fullAddress: "", // รวมที่อยู่
        provinces: [], //รับค่าตัวแปรจังหวัด
        amphures: [], //รับค่าตัวแปรอำเภอ
        districts: [], //รับค่าตำบล
        buildingName: "", //ชื่ออาคาร

        // road: "",
      },
      UpdateAddress: {
        addrIdU: "",

        buildingNumberU: "", //บ้านเลขที่
        townshipNumberU: "", //หมู่
        townshipNameU: "", //ชื่อหมู่บ้าน
        // subLaneU: "",
        laneU: "", //ซอย
        streetNameU: "", //ชื่อถนน
        districtIdU: "", //ตำบล
        amphurIdU: "", //อำเภอs
        provinceIdU: "", //จังหวัด
        postCodeU: "", //รหัสไปรษณีย์
        // roomnumberU: "", //เลขห้องฝ
        fullAddressU: "", // รวมที่อยู่
        provinces: [], //รับค่าตัวแปรจังหวัด
        amphures: [], //รับค่าตัวแปรอำเภอ
        districts: [], //รับค่าตำบล
        buildingNameU: "", //ชื่ออาคาร
      },
    };
  },
  validations: {
    form: {
      nameTh: {
        required,
      },
      type: {
        required,
      },
    },
  },
  mounted() {},
  computed: {
    fullAddress: function () {
      //

      return `${
        this.tooltipform.buildingNumber != ""
          ? "" + this.tooltipform.buildingNumber
          : this.tooltipform.buildingNumber
      } ${
        this.tooltipform.townshipNumber != ""
          ? "หมู่" + " " + this.tooltipform.townshipNumber
          : this.tooltipform.townshipNumber
      } ${
        this.tooltipform.townshipName != ""
          ? "" + this.tooltipform.townshipName
          : this.tooltipform.townshipName
      } ${
        this.tooltipform.subLane != ""
          ? "ตรอก" + " " + this.tooltipform.subLane
          : this.tooltipform.subLane
      } ${
        this.tooltipform.lane != ""
          ? "ซอย" + " " + this.tooltipform.lane
          : this.tooltipform.lane
      } ${
        this.tooltipform.streetName != "" &&
        this.tooltipform.streetName != undefined
          ? "ถนน" + " " + this.tooltipform.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? "ตำบล" + this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? "อำเภอ" + this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? "จังหวัด" + this.provinceId.nameTh
          : ""
      }  ${
        this.tooltipform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.tooltipform.postCode
          : ""
      }
      `;
    },
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    max() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);

      maxDate.setDate(0);
      // console.log(maxDate);
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.tooltipform.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.tooltipform.prefixIdEn = "Mrs.";
      } else {
        this.tooltipform.prefixIdEn = "Miss.";
      }
    },

    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.postData();
      }
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }

      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getProvinces: function () {
      //  if(this.provinceId != null && this.provinceId != "" && this.provinceId != undefined) {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
          // console.log( this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
      //  }
    },
    getAmphures: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null
      ) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId.provinceId,
            },
          })
          .then((response) => {
            this.amphures = response.data.data;

            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            this.loading = false; //skeleton false
          });
      }
    },
    getDistricts: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null &&
        this.amphurId != null &&
        this.amphurId != ""
      ) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.amphurId.amphureId,
            },
          })
          .then((response) => {
            this.districts = response.data.data;
            if (isReset) {
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },

    getPrefixes: function () {
      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
          // console.log(this.prefixOPtion);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    postData: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/d-audit-subject/store", {
          subjectCode: this.form.subjectCode,
          nameTh: this.form.nameTh,
          nameEn: this.form.nameEn,
          manageBranch: this.form.manageBranch,
          type: this.form.type.id,
        })
        .then((response) => {
          // this.getUser();
          //con
          this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({
            name: "edit-suditSubject",
            params: {
              subjectId: btoa(response.data.subjectId),
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      // this.fullAddress = "";
      this.submitformAddress = false;
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
</style>
